<template>
  <v-app>
    <v-app-bar v-resize="onResize" app color="primary" dark>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon
          v-if="isAdmin || isDsasMarine"
          @click.stop="drawer = !drawer"
          dark
        ></v-app-bar-nav-icon>
        <img
          style="padding-left: 12px; cursor: pointer;"
          height="46"
          src="@/assets/img/logo-gistda-marine-white.png"
          alt=""
          @click="openWebMarine"
        />
        <div v-if="!util.isMobile()" style="margin-left: 20px">
          <h3 id="app-title-t-dsas">THAILAND Digital Shoreline Analysis System (T-DSAS)</h3>
          <div>ระบบเครื่องมือการวิเคราะห์พื้นที่ชายฝั่งทะเลไทย</div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="green" v-if="!$keycloak.authenticated" dark @click="login()">
        เข้าสู่ระบบ
      </v-btn>
      <div v-else class="d-flex justify-center align-center">
        <div v-if="!util.isMobile()" dark class="mr-4 white--text">{{ displayName }}</div>
        <v-btn @click="logout"> ออกจากระบบ </v-btn>
      </div>
    </v-app-bar>
    <v-overlay :value="drawer" z-index="4"></v-overlay>
    <v-navigation-drawer v-model="drawer" temporary app hide-overlay v-if="isAdmin || isDsasMarine" :style="{ top: $vuetify.application.top + 'px', width: 'auto' }">
      <v-list nav dense>
        <v-list-item-group active-class="light-blue--text text--darken-3">
          <v-list-item class="pa-2" to="/analytics-statistics" v-if="isAdmin || isDsasMarine">
            <v-list-item-icon class="mr-2">
              <v-icon small>mdi-text</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><h3 class="font-weight-medium d-inline-flex">ส่วนวิเคราะห์และคำนวณค่าทางสถิติ (Statistics)</h3></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="pa-2" to="/baseline-shoreline" v-if="isAdmin">
            <v-list-item-icon class="mr-2">
              <v-icon :style="{transform: 'rotate(90deg)'}">mdi-import</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><h3 class="font-weight-medium d-inline-flex">นำเข้าข้อมูลเส้นฐานสมมติและเส้นชายฝั่ง</h3></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="pa-2" to="/openapi" v-if="isAdmin || isDsasMarine">
            <v-list-item-icon class="mr-2">
              <v-icon :style="{transform: 'rotate(90deg)'}">mdi-api</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><h3 class="font-weight-medium d-inline-flex">API</h3></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main style="background-color: #F2F8FD;">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    // https://pictogrammers.com/library/mdi/
    items: [
      { title: 'หน้าหลัก', icon: 'mdi-home' },
      { title: 'นำเข้าข้อมูล', icon: 'mdi-format-list-numbered' }
    ],
    isAdmin: false,
    isDsasMarine: false,
    displayName: '',
    keycloak_url: process.env.VUE_APP_KEYCLOAK_URL + 'admin/marine/console'
  }),
  mounted () {
    const self = this
    if (self.$keycloak.token) {
      self.$keycloak
        .loadUserProfile()
        .then(function (profile) {
          self.displayName = profile.firstName && profile.lastName ? profile.firstName + ' ' + profile.lastName : profile.username
        })
        .catch(function (res) {
          console.log(res)
        })
      localStorage.setItem('token', self.$keycloak.token)
      if (self.$keycloak.realmAccess.roles.includes('dsas-admin-marine')) {
        self.isAdmin = true
      }
      if (self.$keycloak.realmAccess.roles.includes('dsas-marine')) {
        self.isDsasMarine = true
      }
    } else {
      if (this.$route.fullPath === '/') {
        window.location.href = this.$keycloak.createLoginUrl()
      }
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }, // test_survey_admin / 123456
    login () {
      window.location.href = this.$keycloak.createLoginUrl()
      self.$router.push('/')
    },
    onResize () {
      if (window.innerWidth >= 480) {
        const root = document.getElementsByTagName('html')[0]
        root.removeAttribute('class', 'hideScroll')
      }
    },
    logout () {
      window.localStorage.removeItem('analysis_result')
      window.localStorage.removeItem('overlayList')
      window.localStorage.removeItem('filter_data')
      this.$keycloak.logout()
    },
    openWebMarine () {
      window.open(process.env.VUE_APP_MARINE_URL)
    }

  }
}
</script>
