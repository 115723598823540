import Vue from 'vue'

const state = {
  shoreline_list: [],
  importPopup: {
    open: false,
    name: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10),
    source: null,
    scale: null,
    gsd: null,
    shp: null,
    loading: false
  },
  shoreline_from_user: [],
  colors: [
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FFA500',
    '#FFFF00',
    '#FF00FF',
    '#00FFFF',
    '#800080',
    '#FFC0CB',
    '#800000',
    '#008000',
    '#000080',
    '#FF4500',
    '#FFD700',
    '#DA70D6',
    '#ADFF2F',
    '#4B0082',
    '#B22222',
    '#FF8C00',
    '#6A5ACD',
    '#2E8B57',
    '#9932CC',
    '#FF69B4',
    '#00CED1',
    '#A0522D',
    '#8B0000',
    '#4682B4',
    '#DC143C',
    '#228B22',
    '#2F4F4F',
    '#8A2BE2'
  ]
}

const getters = {}

const actions = {
  get_shoreline_list: async ({ state, rootState }) => {
    const data = rootState.administrativeDistrictAndBeach
    const params = {
      province_code: data.province?.code,
      amphoe_code: data.amphoe?.code,
      tambon_code: data.tambon?.code,
      main_group: data.beach_main?.code,
      group: data.beach_group?.code,
      beach: data.beach?.code
    }
    const result = await Vue.axios.post('get/line/shoreline', JSON.stringify(params), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.code !== 200) return
    let index = 0
    result?.data?.data?.type?.forEach((element) => {
      element.items?.forEach((it) => {
        index = index + 1
        it.checkbox = false
        if (index - 1 < 20) {
          it.color = state.colors[index - 1]
        } else {
          const crypto = window.crypto || window.msCrypto
          const array = new Uint32Array(5)
          crypto.getRandomValues(array)
          const fileId = array[0] / 4294967296
          it.color = 'hsl(' +
          200 * fileId +
          ',' +
          (100 * fileId) +
          '%,' +
          (60 * fileId) +
          '%)'
        }
      })
    })
    state.shoreline_list = result?.data?.data?.type
  },
  set_shoreline: async ({ state }) => {
    state.importPopup.loading = true
    const data = new FormData()
    data.append('shp', state.importPopup.shp)
    data.append('date', state.importPopup.date)
    data.append('source', state.importPopup.source)
    data.append('scale', state.importPopup.scale)
    data.append('gsd', state.importPopup.gsd)
    const result = await Vue.axios.post('get/shoreline_from_shp', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    state.importPopup.loading = false
    if (result?.data?.code !== 200) return
    Vue.$toast.success('นำเข้าข้อมูลเส้นชายฝั่งจากผู้ใช้งานสำเร็จ')
    state.shoreline_from_user.push({
      wkt: result?.data?.data.wkt,
      name: state.importPopup.name,
      date: state.importPopup.date,
      source: state.importPopup.source,
      scale: state.importPopup.scale,
      gsd: state.importPopup.gsd,
      id: `fromUser_${state.shoreline_from_user.length + 1}`,
      checkbox: false
    })
    state.shoreline_from_user?.forEach((it) => {
      const crypto = window.crypto || window.msCrypto
      const array = new Uint32Array(5)
      crypto.getRandomValues(array)
      const fileId = array[0] / 4294967296
      it.color = 'hsl(' +
      200 * fileId +
      ',' +
      (100 * fileId) +
      '%,' +
      (60 * fileId) +
      '%)'
    })
    state.importPopup.open = false
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
