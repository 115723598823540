import Vue from 'vue'

const state = {
  tab: 0,
  province_list: [],
  amphoe_list: [],
  tambon_list: [],
  beachmain_list: [],
  beachgroup_list: [],
  beach_list: [],
  province: null,
  amphoe: null,
  tambon: null,
  beach_main: null,
  beach_group: null,
  beach: null,
  loading: false,
  clear_loading: false,
  searched_data: {
    province: null,
    amphoe: null,
    tambon: null,
    beach_main: null,
    beach_group: null,
    beach: null
  }
}

const getters = {}

const actions = {
  get_province_list: async ({ state }) => {
    const result = await Vue.axios?.get('get/ref/province')
    if (result?.data?.code !== 200) return
    state.province_list = result?.data?.data
  },
  get_amphoe_list: async ({ state }) => {
    state.amphoe = null
    state.tambon = null
    state.amphoe_list = []
    state.tambon_list = []
    if (state.province) {
      const result = await Vue.axios?.get('get/ref/amphoe', { params: { province_code: state.province.code } })
      if (result?.data?.code !== 200) return
      state.amphoe_list = result?.data?.data
    }
  },
  get_tambon_list: async ({ state }) => {
    state.tambon = null
    state.tambon_list = []
    if (state.province && state.amphoe) {
      const result = await Vue.axios?.get('get/ref/tambon', { params: { province_code: state.province.code, amphoe_code: state.amphoe.code } })
      if (result?.data?.code !== 200) return
      state.tambon_list = result?.data?.data
    }
  },
  get_beachmain_list: async ({ state }) => {
    const result = await Vue.axios?.get('get/ref/beachmain')
    if (result?.data?.code !== 200) return
    state.beachmain_list = result?.data?.data
  },
  get_beachgroup_list: async ({ state }) => {
    state.beach_group = null
    state.beach = null
    state.beachgroup_list = []
    state.beach_list = []
    if (state.beach_main) {
      const result = await Vue.axios?.get('get/ref/beachgroup', { params: { main_group: state.beach_main.code } })
      if (result?.data?.code !== 200) return
      state.beachgroup_list = result?.data?.data
    }
  },
  get_beach_list: async ({ state }) => {
    state.beach = null
    state.beach_list = []
    if (state.beach_group) {
      const result = await Vue.axios?.get('get/ref/beach', { params: { group: state.beach_group.code } })
      if (result?.data?.code !== 200) return
      state.beach_list = result?.data?.data
    }
  },
  search: async ({ state, rootState, dispatch }) => {
    if (state.province && state.beach_main) {
      Vue.$toast.error('กรุณาเลือกเงื่อนไขการค้นหาเพียงประเภทเดียว (ตามเขตการปกครอง หรือ ตามกลุ่มหาด)')
    } else if (!state.province && !state.beach_main) {
      Vue.$toast.error('กรุณาเลือกเงื่อนไขการค้นหา')
    } else {
      state.loading = true
      rootState.shoreline.shoreline_list = []
      dispatch('baseline/get_baseline_list', null, { root: true })
      await dispatch('baseline/clear', null, { root: true })
      await dispatch('mapStatistics/clear', null, { root: true })
      await dispatch('settingBaselinePopup/clear_data', null, { root: true })
      await dispatch('shoreline/get_shoreline_list', null, { root: true }).then(() => {
        state.loading = false
        state.searched_data = {
          province: state.province,
          amphoe: state.amphoe,
          tambon: state.tambon,
          beach_main: state.beach_main,
          beach_group: state.beach_group,
          beach: state.beach
        }
      })
    }
  },
  clear: ({ state, rootState, dispatch }) => {
    state.amphoe_list = []
    state.tambon_list = []
    state.beachgroup_list = []
    state.beach_list = []
    state.province = null
    state.amphoe = null
    state.tambon = null
    state.beach_main = null
    state.beach_group = null
    state.beach = null
    state.clear_loading = true
    state.searched_data = {
      province: null,
      amphoe: null,
      tambon: null,
      beach_main: null,
      beach_group: null,
      beach: null
    }
    rootState.shoreline.shoreline_list = []
    dispatch('baseline/clear', null, { root: true })
    dispatch('mapStatistics/clear', null, { root: true })
    dispatch('settingBaselinePopup/clear_data', null, { root: true })
    setTimeout(() => {
      state.clear_loading = false
    }, 200)
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
