import router from '@/router'
import Vue from 'vue'
import { Utils } from '@/assets/js/utility'

const util = new Utils()

const state = {
  open: false,
  loading: false,
  analysis_name: null,
  analysis_type: [],
  before_shoreline: null,
  after_shoreline: null,
  shoreline_list: [],
  analysis_result: null,
  shoreline_forecast_type_list: [],
  shoreline_forecast_type: null,
  forecast_year: null,
  forecast_result: null,
  forecastBtnLoading: false,
  noGsd: [],
  need_epr_legend: false,
  epr_legend: [],
  disableBtn: false,
  analysis_shoreline_list: [],
  analysisShorelineSelected: [],
  select_all: true
}

const getters = {}

const actions = {
  get_analysis_type: async ({ state }) => {
    const result = await Vue.axios?.get('get/ref/analysis_type')
    if (result?.data?.code !== 200) return
    state.analysis_type = result?.data?.data
  },
  open_popup: ({ state, rootState }) => {
    const shorelineList = rootState.baseline.shoreline_list
    const transectsWkt = rootState.settingBaselinePopup.apply_data.transects
    const filterData = rootState.administrativeDistrictAndBeach.searched_data
    state.shoreline_list = rootState.baseline.shoreline_list
    // insert analysis name
    if (filterData.province) {
      state.analysis_name = `${filterData.tambon
        ? `${filterData.tambon.name_th}, ${filterData.amphoe.name_th}, ${filterData.province.name_th}`
      : filterData.amphoe ? `${filterData.amphoe.name_th}, ${filterData.province.name_th}` : filterData.province.name_th}`
    } else if (filterData.beach_main) {
      state.analysis_name = `${filterData.beach
        ? `${filterData.beach.name_th}, ${filterData.beach_group.name_th}, ${filterData.beach_main.name_th}`
      : filterData.beach_group ? `${filterData.beach_group.name_th}, ${filterData.beach_main.name_th}` : filterData.beach_main.name_th}`
    } else {
      state.analysis_name = null
    }
    if (!transectsWkt) {
      state.analysis_type[0].items[2].disable = true
    } else {
      if (shorelineList.length > 1) {
        shorelineList?.sort((a, b) => {
          return new Date(a.date) - new Date(b.date)
        })
        const date1 = shorelineList[0].date.split('-')
        const date2 = shorelineList[shorelineList.length - 1].date.split('-')
        if (date1[0] === date2[0] && date1[1] === date2[1]) {
          state.analysis_type[0].items[2].disable = true
        } else {
          state.analysis_type[0].items[2].disable = false
        }
      } else {
        state.analysis_type[0].items[2].disable = true
      }
    }
    const arr = []
    const noGsd = shorelineList.filter((item) => !item.gsd || item.gsd === 0)
    noGsd?.forEach((item) => {
      arr.push(item.name)
    })
    state.noGsd = arr
    state.analysis_type?.forEach(group => {
      group.items.forEach((it) => {
        if (group.group_id === 1) {
          if (it.id !== 3) {
            if (!transectsWkt) {
              it.disable = true
            } else {
              it.disable = false
            }
          }
          if (it.id === 5) {
            if (noGsd.length > 0 || !transectsWkt) {
              it.disable = true
            } else {
              it.disable = false
            }
          }
        }
      })
    })
    state.open = true
  },
  analysis: async ({ state, rootState }, payload) => {
    state.loading = true
    const transectData = rootState.settingBaselinePopup.apply_data
    const shorelineList = rootState.baseline.shoreline_list
    const baseline = rootState.baseline
    const searchData = rootState.administrativeDistrictAndBeach.searched_data
    const shorelines = []
    const analysisTypeIds = []
    let baselineWkt = null
    shorelineList?.forEach(it => {
      shorelines.push({
        date: it.date,
        wkt: it.wkt,
        gsd: parseFloat(it.gsd) !== 0 || it.gsd ? it.gsd : null,
        name: it.name
      })
    })
    payload.forEach(it => {
      analysisTypeIds.push(it.id)
    })
    const findId = (id) => id === 3
    state.need_epr_legend = analysisTypeIds.some(findId)
    if (baseline.radio_use_from === 'from_system') {
      baselineWkt = baseline.baseline?.wkt
    } else if (baseline.radio_use_from === 'from_user') {
      if (baseline.radio_from_user === 'line_coast') {
        baselineWkt = baseline.baseline_from_shoreline?.wkt
      } else if (baseline.radio_from_user === 'draw_map') {
        baselineWkt = baseline.measure
      } else if (baseline.radio_from_user === 'import_shapefile') {
        baselineWkt = baseline.wkt_from_shp
      }
    }
    const params = {
      analysis_name: state.analysis_name,
      analysis_type_ids: analysisTypeIds.toString(),
      baseline: transectData.baseline ? transectData.baseline : baselineWkt,
      shorelines: shorelines,
      transects: transectData.transects,
      length: transectData.length,
      spacing: transectData.spacing,
      direction: !transectData.transect_file?.name ? transectData.direction : null,
      simplify: transectData.simplify,
      smoothing: transectData.smoothing,
      before_shoreline: { name: state.before_shoreline?.name, wkt: state.before_shoreline?.wkt },
      after_shoreline: { name: state.after_shoreline?.name, wkt: state.after_shoreline?.wkt },
      user_token: Vue.$keycloak?.token,
      province_code: searchData.province?.code,
      amphoe_code: searchData.amphoe?.code,
      tambon_code: searchData.tambon?.code,
      main_group: searchData.beach_main?.code,
      group: searchData.beach_group?.code,
      beach: searchData.beach?.code
    }
    const result = await Vue.axios.post('set/analysis', JSON.stringify(params), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    state.loading = false
    if (result?.data?.code !== 200) return
    state.analysis_result = result?.data?.data
    const shorelineChekedList = []
    baseline.shoreline_list.forEach((it) => {
      shorelineChekedList.push(it.id)
    })
    let baselineData = null
    if (baseline.radio_from_user || baseline.radio_use_from) {
      if (!baseline.radio_from_user) {
        baselineData = baseline.baseline?.id
      } else {
        switch (baseline.radio_from_user) {
          case 'line_coast':
            baselineData = {
              shoreline_model: baseline.shoreline_model.id,
              distance: baseline.distance,
              buffer_model: baseline.buffer_model
            }
            break
          case 'draw_map':
            baselineData = baseline.measure
            break
          case 'import_shapefile':
            baselineData = {
              name: baseline.shp_file?.name,
              wkt: baseline.wkt_from_shp
            }
            break
          default:
            baselineData = null
        }
      }
    }
    util.setLocalStorage('analysis_result', JSON.stringify(result?.data?.data))
    util.setLocalStorage('analysis_data', JSON.stringify({
      searched_data: searchData,
      shoreline: shorelineChekedList,
      baseline: {
        radio: baseline.radio_from_user ? baseline.radio_from_user : baseline.radio_use_from,
        data: baselineData
      },
      shoreline_from_user: rootState.shoreline.shoreline_from_user,
      transects: {
        wkt: transectData.transects,
        length: transectData.length,
        spacing: transectData.spacing,
        direction: transectData.direction,
        simplify: transectData.simplify,
        smoothing: transectData.smoothing,
        transect_file: transectData.transect_file?.name,
        need_epr_legend: state.need_epr_legend,
        baseline: transectData.baseline
      }
    }))
    state.open = false
    router.push({ name: 'analysis_results_path' })
  },
  validate_shoreline: async ({ state }, val) => {
    if (state.before_shoreline?.id === state.after_shoreline?.id) {
      Vue.$toast.error('กรุณาเลือกเส้นชายฝั่งที่ไม่ซ้ำกัน')
      if (val === 'before_shoreline') {
        await (state.before_shoreline)
        state.before_shoreline = null
      } else {
        await (state.after_shoreline)
        state.after_shoreline = null
      }
    }
  },
  get_shoreline_forecast_type: async ({ state }) => {
    const result = await Vue.axios?.get('get/ref/shoreline_forecast_type')
    if (result?.data?.code !== 200) return
    state.shoreline_forecast_type_list = result?.data?.data
    state.shoreline_forecast_type = result?.data?.data[0]?.id
  },
  goBack: async ({ state, rootState, dispatch, commit }) => {
    state.disableBtn = true
    router.push({ name: 'analytics_statistics_path' })
    const analysisData = JSON.parse(util.getLocalStorage('analysis_data'))
    const filterData = rootState.administrativeDistrictAndBeach
    const baseline = rootState.baseline
    filterData.province = analysisData.searched_data.province
    filterData.beach_main = analysisData.searched_data.beach_main

    if (filterData.province) {
      filterData.tab = 0
      dispatch('administrativeDistrictAndBeach/get_amphoe_list', null, { root: true })
      filterData.amphoe = analysisData.searched_data.amphoe
      if (filterData.amphoe) dispatch('administrativeDistrictAndBeach/get_tambon_list', null, { root: true })
      filterData.tambon = analysisData.searched_data.tambon
    }
    if (filterData.beach_main) {
      filterData.tab = 1
      dispatch('administrativeDistrictAndBeach/get_beachgroup_list', null, { root: true })
      filterData.beach_group = analysisData.searched_data.beach_group
      if (filterData.beach_group) dispatch('administrativeDistrictAndBeach/get_beach_list', null, { root: true })
      filterData.beach = analysisData.searched_data.beach
    }
    // set shoreline
    const shorelineChekedList = []
    await dispatch('administrativeDistrictAndBeach/search', null, { root: true }).then(() => {
      rootState.shoreline.shoreline_list.forEach((it) => {
        it.items.forEach((_it) => {
          if (analysisData.shoreline.includes(_it.id)) {
            _it.checkbox = true
            shorelineChekedList.push(_it)
          }
        })
      })
    })
    for (const list of shorelineChekedList) {
      await dispatch('mapStatistics/set_shoreline', list, { root: true })
    }
    rootState.shoreline.shoreline_from_user = analysisData.shoreline_from_user
    for (const list of rootState.shoreline.shoreline_from_user) {
      await dispatch('mapStatistics/set_shoreline', list, { root: true })
    }
    // set baseline
    if (analysisData.baseline.radio) {
      if (analysisData.baseline.radio === 'from_system') {
        baseline.radio_use_from = analysisData.baseline.radio
        baseline.baseline = baseline.baseline_list.find((item) => item.id === analysisData.baseline.data)
      } else {
        baseline.radio_use_from = 'from_user'
        baseline.radio_from_user = analysisData.baseline.radio
        switch (analysisData.baseline.radio) {
          case 'line_coast':
            baseline.buffer_model = analysisData.baseline.data.buffer_model
            baseline.distance = analysisData.baseline.data.distance
            baseline.shoreline_model = baseline.shoreline_list.find((item) => analysisData.baseline.data.shoreline_model === item.id)
            dispatch('baseline/get_baseline_from_shoreline', null, { root: true })
            break
          case 'draw_map':
            baseline.measure = analysisData.baseline.data
            break
          case 'import_shapefile':
            baseline.shp_file = { name: analysisData.baseline.data.name }
            baseline.wkt_from_shp = analysisData.baseline.data.wkt
            break
          default:
            break
        }
      }
    }
    // set transect
    rootState.settingBaselinePopup.apply_data = {
      transects: analysisData.transects.wkt,
      length: analysisData.transects.length,
      spacing: analysisData.transects.spacing,
      direction: analysisData.transects.direction,
      simplify: analysisData.transects.simplify,
      smoothing: analysisData.transects.smoothing,
      baseline: analysisData.transects.baseline,
      transect_file: analysisData.transects?.transect_file ? { name: analysisData.transects?.transect_file } : null
    }
    rootState.settingBaselinePopup.transects = {
      baseline_simplify: analysisData.transects.baseline,
      transects: analysisData.transects.wkt
    }
    rootState.mapStatistics.clear_data = false
    state.disableBtn = false
  },
  set_forecast: async ({ state }) => {
    const analysisShorelineIds = []
    state.analysisShorelineSelected?.forEach((item) => {
      analysisShorelineIds.push(item.id.toString())
    })
    state.forecastBtnLoading = true
    const params = {
      year: state.forecast_year,
      analysis_id: JSON.parse(util.getLocalStorage('analysis_result')).analysis_id.toString(),
      forecast_type_id: state.shoreline_forecast_type.toString(),
      analysis_shoreline_ids: analysisShorelineIds
    }
    const result = await Vue.axios.post('set/forecast', JSON.stringify(params), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    state.forecastBtnLoading = false
    if (result?.data?.code !== 200) return
    state.forecast_result = result?.data?.data
  },
  get_epr_legend: async ({ state }) => {
    state.epr_legend = []
    const result = await Vue.axios?.get('get/epr_legend')
    if (result?.data?.code !== 200) return
    state.epr_legend = result?.data?.data
  },
  get_analysis_shoreline: async ({ state }) => {
    const result = await Vue.axios?.get('get/ref/analysis_shoreline', { params: { analysis_id: JSON.parse(util.getLocalStorage('analysis_result')).analysis_id.toString() } })
    if (result?.data?.code !== 200) return
    state.analysis_shoreline_list = result?.data?.data
    state.analysisShorelineSelected = result?.data?.data
  },
  select_all: ({ state }) => {
    if (state.select_all) {
      state.analysisShorelineSelected = state.analysis_shoreline_list
    } else {
      state.analysisShorelineSelected = []
    }
  },
  select_analysis_shoreline_list: ({ state }) => {
    if (state.analysisShorelineSelected.length !== state.analysis_shoreline_list.length) {
      state.select_all = false
    } else {
      state.select_all = true
    }
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
