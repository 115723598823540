import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/AnalyticsAndStatisticsView.vue')
  },
  {
    path: '/analytics-statistics',
    name: 'analytics_statistics_path',
    component: () => import('../views/AnalyticsAndStatisticsView.vue')
  },
  {
    path: '/baseline-shoreline',
    name: 'baseline_shoreline_path',
    component: () => import('../views/ImportBaselineAndShorelineView.vue')
  },
  {
    path: '/analysis-results',
    name: 'analysis_results_path',
    component: () => import('../views/AnalysisResultsView.vue')
  },
  // {
  //   path: '/transect-test',
  //   name: 'transect_test',
  //   component: () => import('../views/TransectTest.vue')
  // },
  {
    path: '/openapi',
    name: 'openapi',
    component: () => import('../views/OpenApi.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
