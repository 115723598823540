import Vue from 'vue'

const state = {
  shoreline_coordinates: [],
  clear_data: false,
  prevRoute: null,
  marine_shoreline_list: []
}

const getters = {
  getterShoreline: (state) => {
    return {
      item: state.shoreline_coordinates,
      checkbox: state.shoreline_coordinates.checkbox
    }
  }
}

const actions = {
  set_shoreline: ({ state, dispatch }, payload) => {
    state.shoreline_coordinates = payload
    dispatch('baseline/set_shorline', payload, { root: true })
  },
  get_marine_shoreline: async ({ state }) => {
    const result = await Vue.axios?.get('get/ref/marine/shoreline')
    if (result?.data?.code !== 200) return
    state.marine_shoreline_list = result?.data?.data
  },
  clear: ({ state }) => {
    state.clear_data = true
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
