import Vue from 'vue'

const state = {
  shoreline_list: [],
  preview_popup: {
    name: '',
    wkt: '',
    open: false,
    id: null
  },
  open_edit_shoreline_popup: false,
  loading: true,
  ref_shoreline_type: [],
  ref_marine_shoreline: []
}

const getters = {}

const actions = {
  get_shoreline: async ({ state }) => {
    state.loading = true
    const result = await Vue.axios?.get('get/ref/shoreline')
    if (result?.data?.code !== 200) {
      Vue.$toast?.error(result.data.message)
      state.loading = false
    } else {
      state.shoreline_list = result?.data?.data
      state.loading = false
    }
  },
  delete_shoreline: async ({ state }, id) => {
    const result = await Vue.axios.post('delete/ref/shoreline?id=' + id, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.code !== 200) return
    Vue.$toast.success(result?.data?.message)
  },
  set_preview_popup: ({ state }, payload) => {
    state.preview_popup = payload
  },
  get_ref_shoreline_type: async ({ state }) => {
    const result = await Vue.axios?.get('get/ref/shoreline_type')
    if (result?.data?.code !== 200) {
      Vue.$toast?.error(result.data.message)
    } else {
      state.ref_shoreline_type = result?.data?.data
    }
  },
  get_ref_marine_shoreline: async ({ state }) => {
    const result = await Vue.axios?.get('get/ref/marine/shoreline')
    if (result?.data?.code !== 200) return
    state.ref_marine_shoreline = result?.data?.data
  },
  set_loading: ({ state }) => {
    state.loading = true
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
