import Vue from 'vue'
import Vuex from 'vuex'
import administrativeDistrictAndBeach from '@/store/modules/AnalyticsAndStatistics/AdministrativeDistrictAndBeach'
import baseline from './modules/AnalyticsAndStatistics/Baseline'
import shoreline from './modules/AnalyticsAndStatistics/Shoreline'
import mapStatistics from './modules/AnalyticsAndStatistics/MapStatistics'
import importShorelinePopup from './modules/ImportData/ImportShorelinePopup'
import importBaselinePopup from './modules/ImportData/ImportBaselinePopup'
import settingBaselinePopup from './modules/AnalyticsAndStatistics/SettingBaselinePopup'
import analysisShorelinePopup from './modules/AnalyticsAndStatistics/AnalysisShorelinePopup'
import tabShoreline from './modules/ImportBaselineAndShoreline/TabShoreline'
import tabBaseline from './modules/ImportBaselineAndShoreline/TabBaseline'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    administrativeDistrictAndBeach,
    baseline,
    shoreline,
    mapStatistics,
    importShorelinePopup,
    importBaselinePopup,
    settingBaselinePopup,
    analysisShorelinePopup,
    tabShoreline,
    tabBaseline
  }
})

export default store
