import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: '#185787',
        secondary: '#74B8C7',
        success: '#76BA99',
        normal: '#02090E',
        gray: '#929292',
        error: '#EB4747',
        inactive: '#D94646'
        // accent: colors.indigo.base, // #3F51B5
        // error: colors.red.accent3,
      }
    }
  }
}

export default new Vuetify(opts)
