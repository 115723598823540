import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import authentication from './plugins/keycloak-plugin'
import { Utils } from './assets/js/utility'
import './assets/css/global.scss'
import './assets/css/custom-swagger.scss'
import LongdoMap from 'longdo-map-vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import HighchartsVue from 'highcharts-vue'
import variablepie from 'highcharts/modules/variable-pie'
import Highcharts from 'highcharts'

Vue.use(LongdoMap, {
  load: {
    apiKey: process.env.VUE_APP_LONGDOMAP_API_KEY
  }
})
Vue.use(authentication)
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = process.env.VUE_APP_DSAS_API

Vue.use(Toast, {})

Vue.use(HighchartsVue, variablepie)
variablepie(Highcharts)
const util = new Utils()
Vue.utils = util

Vue.axios.interceptors.response.use(
  (response) => response,
  (error) => {
    Vue.$toast.error(error?.response?.data?.message || error?.response?.data)
    return error
  }
)

Vue.mixin({
  data () {
    return {
      util
    }
  }
})

Vue.$keycloak
  .init({
    checkLoginIframe: false, onLoad: 'check-sso'
  })
  // .init({ onLoad: 'check-sso' })
  .then(() => {
    new Vue({
      router,
      vuetify,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
