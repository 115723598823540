import Vue from 'vue'

const state = {
  open: false,
  baseline: null,
  baseline_original: null,
  length: null,
  spacing: null,
  direction: 'R',
  simplify: null,
  smoothing: null,
  disableApplyBtn: true,
  transectBtnLoading: false,
  transects: null,
  new_transect: null,
  transect_file: null,
  import_transect_result: null,
  calculate_data: {
    length: null,
    spacing: null,
    direction: null,
    simplify: null,
    smoothing: null
  },
  apply_data: {
    baseline: null,
    length: null,
    spacing: null,
    direction: null,
    simplify: null,
    smoothing: null,
    transects: null,
    transect_file: null
  }
}

const getters = {}

const actions = {
  set_transect: async ({ state }) => {
    state.transectBtnLoading = true
    state.disableApplyBtn = true
    if (parseFloat(state.simplify) === 0 || !state.simplify) {
      state.simplify = null
    }
    if (parseFloat(state.smoothing) === 0 || !state.smoothing) {
      state.smoothing = null
    }
    const params = {
      baseline: state.baseline_original,
      length: parseFloat(state.length),
      spacing: parseFloat(state.spacing),
      direction: state.direction,
      simplify: state.simplify ? parseFloat(state.simplify) : null,
      smoothing: state.smoothing ? parseFloat(state.smoothing) : null
    }
    const result = await Vue.axios.post('set/transect', JSON.stringify(params), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.code === 200) {
      Vue.$toast.success(result?.data?.message)
      state.disableApplyBtn = false
      state.transects = result?.data?.data
      state.calculate_data = {
        length: parseFloat(state.length),
        spacing: parseFloat(state.spacing),
        direction: state.direction,
        simplify: state.simplify ? parseFloat(state.simplify) : null,
        smoothing: state.smoothing ? parseFloat(state.smoothing) : null
      }
    }
    state.transectBtnLoading = false
  },
  update_transect: async ({ state }, transect) => {
    const params = {
      baseline: state.baseline,
      transect
    }
    const result = await Vue.axios.post('update/transect', JSON.stringify(params), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.code === 200) {
      state.new_transect = result?.data?.data
    }
  },
  clear_data: ({ state }) => {
    state.length = null
    state.spacing = null
    state.direction = 'R'
    state.simplify = null
    state.smoothing = null
    state.disableApplyBtn = true
    state.transects = null
    state.apply_data = {
      baseline: null,
      length: null,
      spacing: null,
      direction: null,
      simplify: null,
      smoothing: null,
      transects: null
    }
    state.calculate_data = {
      length: null,
      spacing: null,
      direction: null,
      simplify: null,
      smoothing: null
    }
  },
  set_popup: ({ state, rootState }) => {
    const option = rootState.baseline.radio_use_from
    const fromUser = rootState.baseline.radio_from_user
    if (option === 'from_system') {
      state.baseline_original = rootState.baseline.baseline?.wkt
    } else if (option === 'from_user') {
      if (fromUser === 'line_coast') {
        state.baseline_original = rootState.baseline.baseline_from_shoreline?.wkt
      } else if (fromUser === 'draw_map') {
        state.baseline_original = rootState.baseline.measure
      } else {
        state.baseline_original = rootState.baseline.wkt_from_shp
      }
    }

    state.length = state.apply_data.length
    state.spacing = state.apply_data.spacing
    state.direction = state.apply_data.direction ? state.apply_data.direction : 'R'
    state.simplify = state.apply_data.simplify
    state.smoothing = state.apply_data.smoothing
    state.transect_file = state.apply_data.transect_file ? state.apply_data.transect_file : null
    state.baseline = state.apply_data.baseline ? state.apply_data.baseline : state.baseline_original
    state.transects = {
      baseline_simplify: state.apply_data.baseline ? state.apply_data.baseline : state.baseline_original,
      transects: state.apply_data?.transects ? state.apply_data?.transects : []
    }
    state.calculate_data = {
      length: state.apply_data.length,
      spacing: state.apply_data.spacing,
      direction: state.apply_data.direction ? state.apply_data.direction : 'R',
      simplify: state.apply_data.simplify,
      smoothing: state.apply_data.smoothing
    }
  },
  click_apply: ({ state }, payload) => {
    state.apply_data = {
      baseline: state.transects?.baseline_simplify ? state.transects?.baseline_simplify : state.baseline_original,
      length: !state.transect_file ? state.calculate_data.length ? parseFloat(state.calculate_data.length) : null : null,
      spacing: !state.transect_file ? state.calculate_data.spacing ? parseFloat(state.calculate_data.spacing) : null : null,
      direction: state.calculate_data.direction,
      simplify: !state.transect_file ? state.calculate_data.simplify ? parseFloat(state.calculate_data.simplify) : null : null,
      smoothing: !state.transect_file ? state.calculate_data.smoothing ? parseFloat(state.calculate_data.smoothing) : null : null,
      transects: payload,
      transect_file: state.transect_file
    }
  },
  import_transect: async ({ state }, file) => {
    const data = new FormData()
    state.transect_file = file
    data.append('shp', file)
    const result = await Vue.axios.post('import/transect', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.code !== 200) {
      state.transect_file = null
      state.import_transect_result = null
    } else {
      Vue.$toast.success('Import เส้น Transect สำเร็จ')
      state.import_transect_result = result?.data?.data
    }
  },
  remove_file: ({ state }) => {
    state.transect_file = null
    state.import_transect_result = null
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
