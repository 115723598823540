import Vue from 'vue'

const state = {
  baseline_list: [],
  baseline: null,
  radio_use_from: null,
  radio_from_user: null,
  buffer: [{ option: 'ซ้าย', value: 'L' }, { option: 'ขวา', value: 'R' }],
  buffer_model: 'L',
  distance: 0,
  shoreline_list: [],
  shoreline_model: null,
  baseline_from_shoreline: null,
  measure: null,
  btn_transect: true,
  shp_file: null,
  wkt_from_shp: null
}

const getters = {}

const actions = {
  get_baseline_list: async ({ state, rootState }) => {
    const data = rootState.administrativeDistrictAndBeach
    const params = {
      province_code: data.province?.code,
      amphoe_code: data.amphoe?.code,
      tambon_code: data.tambon?.code,
      main_group: data.beach_main?.code,
      group: data.beach_group?.code,
      beach: data.beach?.code
    }
    const result = await Vue.axios.post('get/line/baseline', JSON.stringify(params), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.code !== 200) return
    state.baseline_list = result?.data?.data
  },
  get_baseline_from_shoreline: async ({ state }) => {
    if (!state.shoreline_model?.wkt) return
    const params = {
      wkt: state.shoreline_model.wkt,
      side: state.buffer_model,
      distance: state.distance.toString()
    }
    const result = await Vue.axios.post('get/baseline_from_shoreline', JSON.stringify(params), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.code !== 200) return
    state.baseline_from_shoreline = result?.data?.data
    state.baseline_from_shoreline.id = state.shoreline_model.id
  },
  change_radio_use_from: ({ state }) => {
    if (state.radio_use_from === 'from_system') {
      state.radio_from_user = null
    }
  },
  clear: ({ state }) => {
    state.baseline_list = []
    state.baseline = null
    state.radio_use_from = null
    state.radio_from_user = null
    state.distance = 0
    state.shoreline_list = []
    state.shoreline_model = null
    state.buffer_model = 'L'
    state.baseline_from_shoreline = null
    state.measure = null
    state.btn_transect = true
    state.shp_file = null
    state.wkt_from_shp = null
  },
  set_shorline: ({ state }, payload) => {
    if (payload.checkbox) {
      state.shoreline_list.push(payload)
    } else {
      const filterData = state.shoreline_list?.filter((it) => it.id !== payload.id)
      state.shoreline_list = filterData
    }
  },
  get_baseline_from_shp: async ({ state }, file) => {
    const data = new FormData()
    state.shp_file = file
    data.append('shp', file)
    const result = await Vue.axios.post('get/baseline_from_shp', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.code !== 200) {
      state.wkt_from_shp = null
      state.shp_file = null
    } else {
      Vue.$toast.success('นำเข้าข้อมูล shapefile สำเร็จ')
      state.wkt_from_shp = result?.data?.data.Wkt
    }
  },
  remove_file: ({ state }) => {
    state.shp_file = null
    state.wkt_from_shp = null
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
