import Vue from 'vue'

const state = {
  baseline_list: [],
  preview_popup: {
    name: '',
    wkt: '',
    open: false,
    id: null
  },
  loading: true,
  open_edit_baseline_popup: false,
  ref_marine_baseline: []
}

const getters = {}

const actions = {
  get_baseline: async ({ state }) => {
    const result = await Vue.axios?.get('get/ref/baseline')
    if (result?.data?.code !== 200) {
      Vue.$toast?.error(result.data.message)
      state.loading = false
    } else {
      state.baseline_list = result?.data?.data
      state.loading = false
    }
  },
  delete_baseline: async ({ state }, id) => {
    const result = await Vue.axios.post('delete/ref/baseline?id=' + id, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.code !== 200) return
    Vue.$toast.success(result?.data?.message)
  },
  set_preview_popup: ({ state }, payload) => {
    state.preview_popup = payload
  },
  get_ref_marine_baseline: async ({ state }) => {
    const result = await Vue.axios?.get('get/ref/marine/baseline')
    if (result?.data?.code !== 200) return
    state.ref_marine_baseline = result?.data?.data
  },
  set_loading: ({ state }) => {
    state.loading = true
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
