import Vue from 'vue'

const state = {
  popup_open: false,
  popup_marine_open: false,
  import_marine_gi_portal: {
    id: null,
    is_from_marine_gi_portal: true,
    name: '',
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10),
    shoreline_type_id: null,
    shoreline_type_name_other: '',
    source: '',
    scale: '',
    gsd: '',
    username: '',
    wkt: '',
    alternate: ''
  },
  import_data_shoreline: {
    id: null,
    is_from_marine_gi_portal: false,
    name: '',
    date: null,
    shoreline_type_id: null,
    shoreline_type_name_other: '',
    source: '',
    scale: '',
    gsd: '',
    username: '',
    shp: null
  },
  edit_data_shoreline: {
    shoreline_type: {
      id: null
    }
  },
  loading: false
}

const getters = {}

const actions = {
  set_ref_shoreline: async ({ state, dispatch }, result) => {
    state.loading = true
    await Vue.axios?.post('set/ref/shoreline', result, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        if (response.data.code !== 200) return
        Vue.$toast.success(response.data.message)
        state.popup_open = false
        state.loading = false
        dispatch('tabShoreline/set_loading', null, { root: true })
      })
      .catch((error) => {
        state.loading = false
        if (error.data.code !== 200) Vue.$toast.error(error.data.message)
      })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
